@import '../../../styles/customMediaQueries.css';

.root {
  padding: 0 24px;
  margin-bottom: 35px;

  @media (--viewportMedium) {
    padding: 0;
    margin-bottom: 52px;
  }
}



.sharebtn {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: bold;
  background-color: var(--successColor);
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.2s ease;
  z-index: 9999;
}
.sharebtn:hover {
  background-color: var(--successColor);
}
.sharebtn svg {
  margin-right: 10px;
}
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9998;
}
.modalcontent {
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  max-width: 600px;
  width: 100%;
  text-align: center;
  animation: modalFadeIn 0.3s ease-out;
}
@keyframes modalFadeIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
.modal ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 20px;
}
.modal li {
  margin-right: 20px;
  margin-bottom: 20px;
}
.modal a,
.modal button {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  color: #333;
  transition: all 0.2s ease;
}
.modal a:hover,
.modal button:hover {
  color: var(--successColor);
}
.modal svg {
  margin-bottom: 10px;
}
.modal button {
  border: none;
  background-color: transparent;
  cursor: pointer;
}
.modal button:focus {
  outline: none;
}
.closebtn {
  top: 10px;
  right: 10px;
  font-size: 24px;
  background-color: transparent;
  border: none;
  color: #333;
  cursor: pointer;
  transition: all 0.2s ease;
  margin-left: 95%;
}
.closebtn:hover {
  color: #007bff;
}

@media (max-width: 768px) {
  /* CSS styles for mobile devices */

  .sharebtn {
    margin-left: 20px;
  }
}
